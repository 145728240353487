<template lang="pug">
transition(name="fade")
  om-toast.mb-5.mt-2(:closable="false" color="warning" type="side")
    .alert-block-header.mb-3.align-items-center(v-html="getAlertTitle(integrationType)")
    .alert-block.mb-2
      .alert-block-wrapper
        .alert-block-title.d-block(
          v-html="$t(`integrations.${integrationType}.reIntegration.alert.altTitle`)"
        )
        .alert-block-description.d-inline-block(
          v-html="$t(`integrations.${integrationType}.reIntegration.alert.description`)"
        )
</template>
<script>
  import reIntegrationMixin from '../reIntegration';

  export default {
    mixins: [reIntegrationMixin],
    props: {
      integrationType: {
        type: String,
        required: true,
      },
    },
  };
</script>
